import React from 'react';
import { Link } from 'gatsby';
import { Layout } from '../layout';
import styled from 'styled-components';
import Seo from '../utils/seo';
import { device } from '../utils/devices';
import { TypewriterText, Text } from '../components';

const StyledLink = styled(Link)`
  fill: var(--color-onSurfaceHigh);
  transition-property: margin, padding, width, height, color, background-color,
    fill;
  transition-duration: 0.2s;
  transition-timing-function: ease-in;

  display: flex;
  &:hover {
    fill: var(--color-onSurfaceMedium);
  }
  &:focus {
    outline: none;
    background-color: var(--color-overlay03);
  }
  &:focus:not(:focus-visible) {
    background-color: transparent;
  }
  &:focus-visible {
    fill: var(--color-onSurfaceHigh);
    outline: 2px dashed var(--color-onSurfaceHigh);
    background-color: var(--color-overlay03);
  }
  &:active {
    background-color: var(--color-overlay03);
  }
`;

const PageHeader = styled.div`
  position: sticky;
  inset: 64px auto auto;
  scroll-margin-top: 64px;
  z-index: 1;

  height: calc(100vh - 64px - 2.5rem);
  align-items: center;
  display: flex;
  flex-flow: column;
  flex: 1 1 100%;
  padding-block-start: calc((100vh - 64px - 2.5rem) / 2 - 120px);
`;

const PageHeaderTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  padding-block: 24px;
  @media ${device.tablet} {
    padding-block: 32px;
  }
  @media ${device.laptop} {
    padding-block: 40px;
  }
`;
const PageHeaderContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;

  padding-block: 24px;
  @media ${device.tablet} {
    padding-block: 32px;
  }
  @media ${device.laptop} {
    padding-block: 40px;
  }
`;

const ErrorPage: React.FC = () => (
  <>
    <Layout>
      <PageHeader>
        <PageHeaderTitleWrapper>
          <Text heading2 as="h1">
            <TypewriterText firstWords={['404']} />
          </Text>
          <Text heading4 as="h2">
            Not all who wander are lost
          </Text>
        </PageHeaderTitleWrapper>

        <PageHeaderContent>
          <Text heading5 alignCenter as="p">
            <StyledLink to="/">
              It&apos;s never too late to turn back though.
            </StyledLink>
          </Text>
        </PageHeaderContent>
      </PageHeader>
    </Layout>
  </>
);

export default ErrorPage;

export const Head = () => <Seo title="404" slug={'/404/'} />;
